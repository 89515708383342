
@font-face {
  font-family: 'Podkova'; /* You can name it anything */
  src: url('./fonts/Podkova-VariableFont_wght.ttf') format('truetype'); /* Path to your font file */
}

@font-face {
  font-family: 'InstrumentSans'; /* You can name it anything */
  src: url('./fonts/InstrumentSans-VariableFont_wdth,wght.ttf') format('truetype'); /* Path to your font file */
}

html, body, #root {
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  /* align-items: top; */
  justify-content: flex-start;
  background-color: #f1faee;
  height: 100%;
  width: 80%;
  min-width: 920px;
  margin: auto;
}

.App-column {
  display: flex;
  /* flex-direction: colu/mn; */
  justify-content: space-between;
  background-color: #f1faee;
  /* padding: 40px; */
  height: 95vh;
  /* height: 90%; */
}

.App-column > div {
  width: 50%;
}

.App-title {
  text-align: center;
  background-color: #1D3557;
  color: #F1FAEE;
  padding: 10px 0;
}

.Section-left {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding: 40px 5% 0 5%;
  /* padding: 20px 0; */
}


.Section-title {
  text-align: center;
  /* font-family: Garamond, serif; */
  font-family: 'Podkova', sans-serif; 
  font-size: 80px;
  font-weight: 800;
  line-height: .9;
  /* background-color: white; */
  color: black;
  /* padding: 20px 0; */
}

.Section-subtitle {
  text-align: left;
  font-family: 'InstrumentSans', sans-serif; 
  font-size: 18px;
  font-weight: bold;
  /* background-color: white; */
  color: #1D3557;
  margin-left: 7px;
  /* padding: 20px 0; */
}

/* .Section-title,
.Section-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.product-name-row {
  font-family: 'InstrumentSans', sans-serif; 
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  letter-spacing: 2px;
  margin-top: 8px;
  margin-left: 5px;
  /* padding: 20px 0; */
}

.Sub-section-1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  padding-top: 5px;
  /* padding: 20px 0; */
}

.Sub-section-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .image-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: top;
} */

.image1 {
  width: auto;
  height: 110px;
  margin: 20px 0 0 20px;
  
}

.label-box {
  width: 100%;
  border-radius: 10px;
  color: #000;
  font-family: 'Podkova', sans-serif; 
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 50%
}

.vertical-spacer {
  height: 20px;
}

.horizontal-spacer {
  width: 10px;
}

.content {
  font-family: 'InstrumentSans', sans-serif; 
  font-weight: regular;
  font-size: 16px;
  margin-bottom: 20px;
}

.button {
  display: inline-block;
  margin: 10px 40% 10px 0;
  padding: 10px 20px;
  min-width: 200px;
  border-radius: 20px;
  border: none;
  background-color: #1D3557; /* Green */
  color: white;
  text-align: center;
  text-decoration: none;
  font-family: 'InstrumentSans', sans-serif; 
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  cursor: pointer;
}

.button:hover {
  background-color: #fee440;
  color: black;
}

.button.active {
  background-color: #fee440;
  color: black;
}

.disclaimer {
  font-size: 12px;
  font-family: 'InstrumentSans', sans-serif; 
  color: #1D3557;
  margin-top: 20px;
  /* text-align: center; */
  /* margin-top: 20px; */
}

.Section-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding: 30px 5% 0 5%;
  background-color: #A8DADC;
  position: relative;
  height: 96%;
  /* padding: 20px 0; */
}

.chat-container {
  height: 100%;
  /* height: 100vh; */
  width: 100%;
  min-width: 300px;
  bottom: 0;
  animation: slideUp 0.5s forwards;
  background-color: #A8DADC; 
}

.chat-container.close {
  animation: slideDown 0.5s forwards;
}

.chat-window {
  /* margin: 20px; */
  min-height: 500px;
  /* border: .5px solid #9b8b8b; */
  border-radius: 20px;
  background-color: #F1FAEE;
  height: 90%;
  padding: 30px 0 15px 15px;
  /* margin-bottom: 0; */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}


@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

/* .chat-bubble {
  position: absolute;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
} */

.chat-bubble-left {
  display: none;
  /* bottom: 10px; */
}

.chat-bubble-left:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

.chat-bubble-right {
  position: absolute;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.chat-bubble-right:hover {
  transform: scale(1.2);
}

.chat-bubble-icon {
  color: white;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, .5));
}

.mobile-chat {
  display: none;
}

@media (max-width: 768px) {

  .App {
    width: 100%;
    min-width: 370px;
    
    /* max-width: 100vh; */
  }

  .App-title {
    font-size: 12px;
  }

  .App-column > div {
    width: 100%;
    height: 90%;
    /* height: 100vh; */
  }

  .App-title {
    /* text-align: center;
    background-color: #1D3557;
    color: #F1FAEE; */
    /* padding: 10px 0; */
    width: 100%;
  }

  .faq-button {
    display: none;
  }

  .mobile-chat {
    display: block;
  }

  .chat-container {
    background-color: #F1FAEE;
  }

  .chat-window {
    height: 95%;
  }

  .Section-left {
    width: 500px;
    padding: 40px 5% 0 5%;
  }

  .Section-right {
    display: none;
  }

  .chat-bubble-right {
    display: none;
  }

  /* .Section-left {
    position: relative;
  } */

  .chat-bubble-left {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 30px;
  }

  .chat-bubble-icon {
    color: #457B9D;
  }

}




.message-row {
  display: flex;
  /* flex-direction: row; */
  align-items: flex-start;
}

.message-row.assistant {
  justify-content: flex-start;
}

.message-row.assistant .message-icon {
  margin-right: 10px;
}

.message-row.user {
  justify-content: flex-end;
}

.message-row.user .message-icon {
  margin-left: 10px;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  height: 92%;
  /* border: 1px solid #000; */
  overflow-y: auto;
}

/* This applies to the scrollbar thumb (the part you drag) */
.chat-messages::-webkit-scrollbar-thumb {
  background: #a1a1a1; /* Color of the scrollbar */
  border-radius: 10px; /* Roundness of the scrollbar */
}

/* This applies when hovering over the scrollbar thumb */
.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color of the scrollbar when hovered */
}

/* This applies to the scrollbar track (the background) */
.chat-messages::-webkit-scrollbar-track {
  background: #dad8d8; /* Color of the track */
}

/* This applies to the scrollbar itself */
.chat-messages::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}


@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.message-bubble {
  max-width: 70%;
  padding: 0px 12px;
  border-radius: 20px;
  margin-bottom: 10px;
  animation: grow 0.3s ease-in-out forwards;
  transform: scale(0);
  font-size: 14px;
}

.message-bubble.show {
  transform: scale(1);
}

.message-bubble.user {
  border-top-right-radius: 0;
  /* margin-right: 3px; */
  align-self: flex-end;
  text-align: right;
  background-color: #e5e5ea;
  color: black;
}

.message-bubble.assistant {
  border-top-left-radius: 0;
  align-items: flex-start;
  text-align: left;
  background-color: #0b93f6;
  color: white;
}

.message-content {
  display: flex;
  align-items: center;
}

.message-icon {
  width: 22px; /* adjust as needed */
  height: 22px; /* adjust as needed */
  border-radius: 50%; /* makes the image circular */
  margin-top: 5px;
  margin-right: 10px; /* space between the icon and the message */
}

.loading {
  display: flex;
  justify-content: center;
  /* font-size: 8px; */
  /* margin-top: 10px; */
}

.beat-loader {
  /* font-size: 8px; */
  color: #457B9D;
}

.input-message-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  /* padding: 20px 0; */
}

.chat-input {
  margin-top: 10px;
  margin-right: 10px;
  /* margin-left: 10px; */
  padding: 0 15px;
  /* margin-bottom: 10px; */
  height: 40px;
  border-radius: 20px;
  border-width: .5px;
  width: 100%;
  align-self: center;
}

.send-button {
  /* display: inline-block; */
  /* margin: 10px 0 10px 0; */
  /* padding: 7px 7px; */
  height: 30px;
  /* min-width: 100px; */
  border-radius: 20px;
  border: none;
  background-color: #1D3557; /* Green */
  color: white;
  text-align: center;
  text-decoration: none;
  /* font-size: 14px; */
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
  align-self: center;
}

.close-icon-container {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
}

.close-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding: 20px 0; */
}

.close-button {
  /* display: inline-block; */
  margin: 5px 0 5px 0;
  padding: 7px 7px;
  min-width: 100px;
  border-radius: 10px;
  border: none;
  background-color: #A8DADC; 
  color: black;
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.faq-title {
  font-size: 24px;
  font-family: 'Podkova', sans-serif; 
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.faq-container {
  font-family: 'InstrumentSans', sans-serif; 
  height: 70vh;
  padding-right: 10px;
  /* height: 300px; */
  overflow-y: auto;
}

/* This applies to the scrollbar thumb (the part you drag) */
.faq-container::-webkit-scrollbar-thumb {
  background: #a1a1a1; /* Color of the scrollbar */
  border-radius: 10px; /* Roundness of the scrollbar */
}

/* This applies when hovering over the scrollbar thumb */
.faq-container::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color of the scrollbar when hovered */
}

/* This applies to the scrollbar track (the background) */
.faq-container::-webkit-scrollbar-track {
  background: #dad8d8; /* Color of the track */
}

/* This applies to the scrollbar itself */
.faq-container::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.faq-question {
  font-size: 18px;
  font-weight: bold;
  margin: 5px;
}

.faq-answer {
  font-size: 16px;
  font-weight: normal;
  margin-left: 10px;
  margin-bottom: 15px;
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
